define('hoshi-i18n/components/i18n/language-picker', ['exports', 'hoshi-i18n/templates/components/i18n/language-picker'], function (exports, _languagePicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var I18nLanguagePickerComponent;

  I18nLanguagePickerComponent = Ember.Component.extend({
    layout: _languagePicker.default,
    tagName: '',
    hoshiI18n: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    portalLanguagesWithoutCurrent: Ember.computed.alias('hoshiI18n.portalLanguagesWithoutCurrent'),
    currentLanguageObject: Ember.computed.alias('hoshiI18n.currentLanguageObject'),
    linkClasses: Ember.computed('currentLanguageObject', function () {
      var classList;
      classList = ["nav-main--items--item", "hoshi-i18n--toolbar--flag-picker", "flag-icon", 'flag-icon-' + this.get('currentLanguageObject.flag_code')];
      return classList.join(" ");
    }),
    actions: {
      setCurrentLanguage: function setCurrentLanguage(lang) {
        var booksRoute;
        this.get('hoshiI18n').set('currentLanguage', lang.get('code'));
        booksRoute = Ember.getOwner(this).lookup('route:books');
        return booksRoute.send('onLanguageChange', lang.get('code'));
      }
    }
  });

  exports.default = I18nLanguagePickerComponent;
});