define('ember-rapid-forms/components/em-form', ['exports', 'ember-rapid-forms/utils/utils', 'ember-rapid-forms/templates/components/em-form'], function (exports, _utils, _emForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _emForm.default,
    tagName: 'form',
    classNameBindings: ['formLayoutClass'],
    attributeBindings: ['role'],
    role: 'form',
    action: 'submit',
    model: null,
    formLayout: 'form',
    submitButton: true,
    validationIcons: true,
    showErrorsOnRender: false,
    showErrorsOnFocusIn: false,
    showErrorsOnSubmit: true,

    isDefaultLayout: _utils.default.createBoundSwitchAccessor('form', 'formLayout', 'form'),
    isInline: _utils.default.createBoundSwitchAccessor('inline', 'formLayout', 'form'),
    isHorizontal: _utils.default.createBoundSwitchAccessor('horizontal', 'formLayout', 'form'),

    formLayoutClass: Ember.computed('formLayout', {
      get: function get() {
        switch (this.get('formLayout')) {
          case 'horizontal':
          case 'inline':
            return "form-" + this.get('formLayout');
          default:
            return 'form';
        }
      }
    }),

    /*
    Form submit
     Optionally execute model validations and perform a form submission.
    */
    actions: {
      submit: function submit() {
        this._submit();
      }
    },

    submit: function submit() {
      this._submit();
      return false;
    },
    _submit: function _submit() {
      var model = this.get('model');

      if (Ember.isNone(this.get('model.validate'))) {
        return this.sendAction('action', model);
      } else {
        return model.validate().then(this._sendAction(model));
      }
    },
    _sendAction: function _sendAction(model) {
      this.set('isSubmitted', true);
      if (model.get('isValid')) {
        return this.sendAction('action', model);
      }
    }
  });
});