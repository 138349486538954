define('hoshi-extensibility/components/extensibility-area', ['exports', 'hoshi-extensibility/templates/components/extensibility-area'], function (exports, _extensibilityArea) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ExtensibilityAreaComponent;

  ExtensibilityAreaComponent = Ember.Component.extend({
    layout: _extensibilityArea.default,
    tagName: '',
    extensibility: Ember.inject.service(),
    name: void 0,
    debug: false,
    _init: Ember.on('init', function () {
      return Ember.defineProperty(this, 'items', Ember.computed('extensibility.areas.' + this.get('name') + '.items[]', function () {
        return this.get('extensibility.areas.' + this.get('name') + '.items');
      }));
    })
  });

  exports.default = ExtensibilityAreaComponent;
});