define('theme-default/locales/en/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var translations;

  translations = {
    tenants: {
      all: {
        headline: 'HIMA',
        subline: 'Smart Together'
      }
    },
    theme: {
      settings: {
        company: 'Firma',
        styles: {
          row: {
            paddingTop: 'Abstand oben?',
            paddingBottom: 'Abstand unten?',
            paddingTopSmall: 'Kleiner Abstand oben',
            paddingBottomSmall: 'Kleiner Abstand unten',
            minimumHeight: 'Mindesthöhe?',
            alignItemsStart: 'Spalten nicht strecken',
            vh30: '30% hoch',
            vh50: '50% hoch',
            vh100: '100% hoch'
          },
          col: {
            paddingTop: 'Abstand oben?',
            paddingBottom: 'Abstand unten?',
            whiteTransparent: 'Weiß transparent?',
            paddingTopSmall: 'Kleiner Abstand oben',
            paddingBottomSmall: 'Kleiner Abstand unten',
            padding: 'Abstand rundherum',
            paddingSmall: 'Kleiner Abstand rundherum',
            foregroundLight: 'Schriftfarbe hell',
            html5Video: 'HTML5 Video'
          }
        }
      }
    },
    toc: {
      p1: {
        headline: 'Editorial'
      },
      p2: {
        headline: 'A Time like No Other',
        subline: 'HIMA Managing Partner Steffen Philipp and CEO Sankar Ramakrishnan share their outlook for the new business year.'
      },
      p3: {
        headline: 'News from the HIMA World',
        subline: 'A summary of all the important updates'
      },
      p4: {
        headline: 'One Unit: Updates on OneGroup and OneIT',
        subline: 'Milestones for global HIMA projects'
      },
      p5: {
        headline: 'Smart Safety and the New HIMA Brand',
        subline: 'In the age of digitization and cyberattacks, safety technology needs to be smart. This is the story behind Smart Safety.'
      },
      p6: {
        headline: 'How Important Is Cybersecurity in Industrial Plants?',
        subline: 'The connected world is vulnerable. Dr. Alexander Horch on IT security risks at process plants.'
      },
      p7: {
        headline: 'Refinery Turnaround: Inspecting Systems in Great Detail',
        subline: 'Complete dismantling and inspection of the petrochemical plant at OMV – that was the task facing HIMA safety engineers.'
      },
      p8: {
        headline: 'Infographic: The Global HIMA Family in Figures',
        subline: 'What experience do our employees have? What does the overall employee structure look like? Interesting facts from our latest HR report.'
      },
      p9: {
        headline: '2018 Outlook',
        subline: 'What’s coming up in 2018.'
      }
    },
    question: {
      headline: 'Ask a Question',
      body: 'Do you have questions or comments relating to this content? Let us know. Privacy is of great importance to us and your details will be submitted anonymously to onebrand@hima.com.<br><br>Thank you!',
      success: 'The question was successfully forwarded.',
      presence: 'Please enter your question.',
      tooShort: 'The question needs to be at least 6 chars long.'
    },
    toc2019: {
      p2: {
        headline: 'We’re All Ambassadors for the HIMA Brand',
        subline: 'Tobias Hoche, Steffen Philipp, and Sankar Ramakrishnan from the HIMA board explain why we can look forward to exciting times ahead'
      },
      p3: {
        headline: 'News from the HIMA World',
        subline: 'A brief overview of the most important topics from 2018'
      },
      p4: {
        headline: 'Setting New Standards: Our Progress with OneGroup and OneIT',
        subline: 'Key areas where HIMA has made progress and the company’s goals for the future'
      },
      p5: {
        headline: 'Where Colleagues and Guests Get Together – New Cafeteria in Brühl',
        subline: 'Our new restaurant concept is about more than just healthy food'
      },
      p6: {
        headline: 'Smart Safety Becomes a Reality',
        subline: 'What began as an idea has now evolved into an established portfolio. Introducing our Smart Safety range for the process industry'
      },
      p7: {
        headline: 'Our Next Step: Raising Awareness with Our Own Events',
        subline: 'How we are boosting our profile in the process and rail industries and strengthening our position globally'
      },
      p8: {
        headline: 'Safety Down Under',
        subline: 'A HIMA engineer gives his account of what it was like to work on the Ichthys megaproject off the coast of Australia'
      },
      p9: {
        headline: 'Recognizing the Achievements of HIMA Employees',
        subline: 'Exceptional performance deserves recognition. We take a look at who the 2018 award winners are'
      },
      p10: {
        headline: 'We’re Celebrating 110 Years!',
        subline: 'The anniversary party in Brühl brought colleagues from all over the world together for a day that will live long in the memory'
      }
    },
    home: {
      title: 'Ready for a Connected World',
      subtitle: 'Secure Login',
      intro: 'The digital magazine is password secured. Please click the button below to sign in with your HIMA credentials.',
      loginButton: 'Login to our annual magazine',
      link: '#/en/books/all/en-smart-together-2019/page/cover',
      authenticated: 'You are authenticated.',
      read: 'Start reading',
      archive: {
        title: 'Archive',
        '2018': {
          title: 'Smart Together 2018',
          subtitle: 'The Smart Age Has Begun.',
          file: '/theme-default/assets/Smart_Together_Edition_1_2018.pdf'
        }
      }
    }
  };

  exports.default = translations;
});