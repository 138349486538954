define('hoshi-analytics-piwik/lib/utils/piwik-url-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  ;
  var PiwikUrlParams;

  PiwikUrlParams = Ember.Object.extend({
    // possible API parameters
    piwikUrlParams: ['module', 'method', 'idSite', 'segment', 'format', 'period', 'date', 'flat'],
    module: 'API',
    method: 'Actions.getPageTitles',
    idSite: void 0, // set from config/environment in _init
    segment: '',
    token_auth: void 0, // set from config/environment in _init
    format: 'json',
    period: '',
    date: '',
    flat: '0',
    _init: Ember.on('init', function () {
      var config;
      config = getOwner(this).resolveRegistration('config:environment');
      this.set('idSite', config.APP.piwikSiteId);
      return this.set('token_auth', config.APP.piwikTokenAuth);
    }),
    // sets a date, used in single API calls (e.g. Summary View)
    setDate: function setDate(date) {
      return this.set('date', this.formatDateForPiwik(date));
    },
    // set dates, used in range API calls (e.g. Charts)
    setDateRange: function setDateRange(dateOne, dateTwo) {
      return this.set('date', this.formatDateForPiwik(dateOne) + ',' + this.formatDateForPiwik(dateTwo));
    },
    // generates the param string
    generateParamString: function generateParamString() {
      var _this = this;

      var paramString, params;
      params = [];
      this.get('piwikUrlParams').forEach(function (param) {
        return params.push(param + '=' + _this.get(param));
      });
      return paramString = params.join("&");
    },
    // util method to format dates for piwik api calls
    formatDateForPiwik: function formatDateForPiwik(date) {
      return moment(date).format('YYYY-MM-DD');
    }
  });

  exports.default = PiwikUrlParams;
});