define('hoshi-i18n/initializers/hoshi-i18n', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  ;
  var HoshiI18nInitializer, HoshiInitializer, initialize;

  // Takes two parameters: container and application
  exports.initialize = initialize = function initialize(app) {
    var config, ref;
    config = app.resolveRegistration('config:environment');
    if (((ref = config.options) != null ? ref.i18n : void 0) != null && config.options.i18n === true) {
      return new HoshiInitializer(app);
    }
  };

  HoshiInitializer = function () {
    function HoshiInitializer(app1) {
      _classCallCheck(this, HoshiInitializer);

      this.app = app1;
      this._addLanguagePickerToNavbar();
      this._modifyBooksRouteModelQuery();
      this._modifyBookModelToAddLanguage();
      this._modifyIndexRouteRedirect();
      this._modifyLinkToHelper();
      this._modifyRouteTransitionTo();
    }

    _createClass(HoshiInitializer, [{
      key: '_modifyRouteTransitionTo',
      value: function _modifyRouteTransitionTo() {
        return Ember.Route.reopen({
          i18n: Ember.inject.service(),
          transitionToI18nSafe: function transitionToI18nSafe() {
            var routeName;

            for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
              args[_key] = arguments[_key];
            }

            if (args[0].match(/^books?/)) {
              routeName = args.shift();
              args.unshift(this.get('i18n.locale'));
              args.unshift(routeName);
            }
            return this.transitionTo.apply(this, args);
          }
        });
      }
    }, {
      key: '_modifyLinkToHelper',
      value: function _modifyLinkToHelper() {
        var linkTo;
        linkTo = this.app.resolveRegistration('component:link-to');
        return linkTo.reopen({
          i18n: Ember.inject.service(),
          didReceiveAttrs: function didReceiveAttrs() {
            var locale, models, targetRouteName;
            this._super();
            targetRouteName = this.get('targetRouteName');
            models = this.get('models');
            if (targetRouteName.match(/^books?/)) {
              locale = this.get('i18n.locale');
              return models.unshift(locale);
            }
          }
        });
      }
    }, {
      key: '_addLanguagePickerToNavbar',
      value: function _addLanguagePickerToNavbar() {
        var bookPageRoute, booksRoute, languagePickerMixin;
        // language picker mixin
        languagePickerMixin = Ember.Mixin.create({
          renderTemplate: function renderTemplate() {
            this._super();
            return this.render('i18n/language-picker', {
              outlet: 'language-picker',
              into: 'application'
            });
          }
        });
        // show on book.page
        bookPageRoute = this.app.resolveRegistration('route:book.page');
        bookPageRoute.reopen(languagePickerMixin);
        // show on books.index
        booksRoute = this.app.resolveRegistration('route:books');
        return booksRoute.reopen(languagePickerMixin);
      }
    }, {
      key: '_modifyBooksRouteModelQuery',
      value: function _modifyBooksRouteModelQuery() {
        var booksRoute;
        booksRoute = this.app.resolveRegistration('route:books');
        return booksRoute.reopen({
          i18n: Ember.inject.service(),
          model: function model(params) {
            if (this.get('i18n.locale')) {
              // modify the params to set the language
              params.language = this.get('i18n.locale');
            }
            return this._super(params);
          },
          actions: {
            // triggered in the language-picker component
            onLanguageChange: function onLanguageChange(langCode) {
              var tenant;
              // get the current tenant
              tenant = this.modelFor('books').tenant;
              // transition to the new start page
              return this.transitionTo('books.index', langCode, tenant);
            }
          }
        });
      }
    }, {
      key: '_modifyBookModelToAddLanguage',
      value: function _modifyBookModelToAddLanguage() {
        var Book;
        Book = this.app.resolveRegistration('model:book');
        return Book.reopen({
          hoshiI18n: Ember.inject.service(),
          language: _emberData.default.attr('string'),
          create: function create() {
            this.set('language', this.get('hoshiI18n.currentLanguage'));
            return this._super();
          }
        });
      }
    }, {
      key: '_modifyIndexRouteRedirect',
      value: function _modifyIndexRouteRedirect() {
        var indexRoute;
        indexRoute = this.app.resolveRegistration('route:index');
        return indexRoute.reopen({
          i18n: Ember.inject.service(),
          beforeModel: function beforeModel() {
            return this.transitionTo('books.index', this.get('i18n.locale'), 'all');
          }
        });
      }
    }]);

    return HoshiInitializer;
  }();

  HoshiI18nInitializer = {
    name: 'hoshi-i18n',
    initialize: initialize
  };

  exports.initialize = initialize;
  exports.default = HoshiI18nInitializer;
});