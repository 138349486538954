define('theme-default/locales/de/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _row, _translations;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var translations;

  translations = (_translations = {
    tenants: {
      all: {
        headline: 'DEUTSCH TITEL',
        subline: 'DEUTSCH SUBTITEL'
      }
    },
    theme: {
      settings: {
        company: 'Firma',
        styles: {
          row: (_row = {
            paddingTop: 'Abstand oben?',
            paddingBottom: 'Abstand unten?',
            paddingTopSmall: 'Kleiner Abstand oben?'
          }, _defineProperty(_row, 'paddingBottom', 'Kleiner Abstand unten?'), _defineProperty(_row, 'minimumHeight', 'Mindesthöhe?'), _defineProperty(_row, 'alignItemsStart', 'Spalten nicht strecken'), _defineProperty(_row, 'vh30', '30% hoch'), _defineProperty(_row, 'vh50', '50% hoch'), _defineProperty(_row, 'vh100', '100% hoch'), _row),
          col: {
            paddingTop: 'Abstand oben?',
            paddingBottom: 'Abstand unten?',
            whiteTransparent: 'Weiß transparent?',
            paddingTopSmall: 'Kleiner Abstand oben',
            paddingBottomSmall: 'Kleiner Abstand unten',
            padding: 'Abstand rundherum',
            paddingSmall: 'Kleiner Abstand rundherum',
            foregroundLight: 'Schriftfarbe hell',
            html5Video: 'HTML5 Video',
            gallery: 'Galerie'
          }
        }
      }
    },
    toc: {
      p1: {
        headline: 'Editorial'
      },
      p2: {
        headline: 'Eine Zeit wie keine andere',
        subline: 'HIMA-Geschäftsführer Steffen Philipp und Sankar Ramakrishnan geben einen Ausblick auf das neue Geschäftsjahr'
      },
      p3: {
        headline: 'Neuigkeiten aus der HIMA-Welt',
        subline: 'Alle wichtigen Nachrichten in Kürze'
      },
      p4: {
        headline: 'Eine Einheit: Updates zu OneGroup und OneIT',
        subline: 'Über die Meilensteine der globalen HIMA-Projekte'
      },
      p5: {
        headline: 'Smart Safety und die neue Marke HIMA',
        subline: 'In Zeiten von Digitalisierung und Cyberattacken muss auch Sicherheitstechnik smart werden. Die Geschichte hinter Smart Safety.'
      },
      p6: {
        headline: 'Wie cybersicher müssen Industrieanlagen sein?',
        subline: 'Die vernetzte Welt ist verwundbar. Dr. Alexander Horch über das IT-Sicherheitsrisiko von Prozessanlagen.'
      },
      p7: {
        headline: 'Raffinerie-Turnaround: Systeme auf Herz und Nieren prüfen',
        subline: 'Den petrochemischen Bereich von OMV komplett zerlegen und inspizieren? Das war Fall für die Safety-Ingenieure von HIMA.'
      },
      p8: {
        headline: 'Infografik: Die globale HIMA-Familie in Zahlen',
        subline: 'Welche Erfahrungen haben die Mitarbeiter? Wie sieht die Mitarbeiterstruktur aus? Spannende Infos aus dem aktuellen Personalbericht.'
      },
      p9: {
        headline: 'Eine Seite Buntes',
        subline: 'Das steht 2018 für uns an.'
      }
    },
    question: {
      headline: 'Frage stellen',
      body: 'Sie haben eine Frage oder Anmerkung zu diesem Inhalt? Gerne! Den Datenschutz halten wir natürlich hoch: Ihre Frage wird anonym an onebrand@hima.com übertragen.<br><br>Danke!',
      success: 'Die Frage wurde erfolgreich weitergeleitet.',
      presence: 'Bitte eine Frage eingeben.',
      tooShort: 'Die Frage muss mindestens 6 Zeichen lang sein.'
    },
    toc2019: {
      p2: {
        headline: 'Wir alle sind Botschafter der Marke HIMA',
        subline: 'Warum wir uns auf spannende Zeiten freuen dürfen, erzählen Tobias Hoche, Steffen Philipp und Sankar Ramakrishnan von der HIMA-Geschäftsführung'
      },
      p3: {
        headline: 'Neuigkeiten aus der HIMA-Welt',
        subline: 'Die wichtigsten Themen im Jahr 2018 in aller Kürze'
      },
      p4: {
        headline: '(K)ein Standardjahr: Unsere Fortschritte bei OneGroup und OneIT',
        subline: 'Das sind die wichtigsten Etappen und neuen Ziele'
      },
      p5: {
        headline: 'Ein Ort der Begegnung – das neue Mitarbeiterrestaurant in Brühl',
        subline: 'Beim neuen Restaurantkonzept geht es um weit mehr als gesunde Speisen'
      },
      p6: {
        headline: 'Smart Safety wird Wirklichkeit',
        subline: 'Was als Idee begann, ist zu einem konkreten Portfolio gewachsen. Wir stellen unser Smart-Safety-Angebot für die Prozessindustrie vor'
      },
      p7: {
        headline: 'Die ersten HIMA-Events: Näher dran an unseren Kunden',
        subline: 'Wie wir unser Profil in der Prozess- und Bahnindustrie schärfen und uns global stärker positionieren'
      },
      p8: {
        headline: 'Sicherheit Down Under',
        subline: 'Ein HIMA-Ingenieur berichtet: So war die Arbeit am Megaprojekt Ichthys vor der Küste Australiens wirklich'
      },
      p9: {
        headline: 'Glänzende Leistung: Die ausgezeichneten Mitarbeiter von HIMA',
        subline: 'Außergewöhnliche Leistungen verdienen Anerkennung! Wir werfen einen Blick auf die Award-Gewinner 2018'
      },
      p10: {
        headline: 'Wir feiern 110 Jahre Zukunft!',
        subline: 'Die Jubiläumsfeier in Brühl brachte Kollegen aus allen Teilen der Welt zusammen – ein Tag, der in Erinnerung bleiben wird'
      }
    }
  }, _defineProperty(_translations, 'toc', {
    title: 'Alle Publikationen',
    readMore: 'lesen >'
  }), _defineProperty(_translations, 'home', {
    title: 'Bereit für eine vernetzte Welt',
    subtitle: 'Sichere Anmeldung',
    intro: 'Das digitale Mitarbeitermagazin ist Passwortgeschützt. Bitte klicken Sie auf die Schaltfläche unten und melden Sie sich anschließend mit Ihrem HIMA-Account an.',
    loginButton: 'Zum Jahresmagazin anmelden',
    link: '#/de/books/all/de-smart-together-2019/page/cover',
    authenticated: 'Sie sind angemeldet.',
    read: 'Das Magazin lesen',
    archive: {
      title: 'Weitere Ausgaben',
      '2018': {
        title: 'Smart Together 2018',
        subtitle: 'Das smarte Zeitalter hat begonnen.',
        file: '/theme-default/assets/Smart_Together_Ausgabe_1_2018.pdf'
      }
    }
  }), _translations);

  exports.default = translations;
});