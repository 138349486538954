define('ember-data-sails/initializers/ember-data-sails', ['exports', 'ember-data', 'ember-data-sails/mixins/with-logger', 'ember-data-sails/mixins/store', 'ember-data-sails/services/sails-socket'], function (exports, _emberData, _withLogger, _store, _sailsSocket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  _emberData.default.Store.reopen(_store.default);

  function initialize(appInstance) {
    var methods, minLevel, shouldLog;
    methods = {};
    minLevel = appInstance.SAILS_LOG_LEVEL;
    shouldLog = false;
    _withLogger.LEVELS.forEach(function (level) {
      if (level === minLevel) {
        shouldLog = true;
      }
      if (!shouldLog) {
        methods[level] = Ember.K;
      }
    });
    _withLogger.default.reopen(methods);

    appInstance.register('service:sails-socket', _sailsSocket.default);

    // setup injections
    appInstance.inject('adapter', 'sailsSocket', 'service:sails-socket');
    appInstance.inject('route', 'sailsSocket', 'service:sails-socket');
    appInstance.inject('controller', 'sailsSocket', 'service:sails-socket');
  }

  var EmberDataSailsInitializer = {
    name: 'ember-data-sails',
    before: 'store',

    initialize: initialize
  };

  exports.default = EmberDataSailsInitializer;
});