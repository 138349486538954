define('hoshi-analytics-piwik/lib/request/piwik-request-clicks', ['exports', 'hoshi-analytics-piwik/lib/data/piwik-data-clicks', 'hoshi-analytics-piwik/lib/request/piwik-request-base'], function (exports, _piwikDataClicks, _piwikRequestBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember = Ember,
      getOwner = _Ember.getOwner;
  ;
  var PiwikRequestClicks;

  PiwikRequestClicks = _piwikRequestBase.default.extend({
    selection: void 0,
    //#############################################################################
    // Public API
    //#############################################################################
    fetch: function fetch() {
      var linkUUIDs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      if (!Ember.isEmpty(this.get('selection.dimension'))) {
        if (this.get('selection.mode.isSummary')) {
          return this._fetchSummary(linkUUIDs);
        } else if (this.get('selection.mode.isComparison')) {
          return this._fetchComparison(linkUUIDs);
        }
      }
      // TODO chart mode? What could happen?
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return reject();
      });
    },
    //#############################################################################
    // Private API
    //#############################################################################
    // Builds the URL, makes a simple request
    _fetchSummary: function _fetchSummary(linkUUIDs) {
      var params, url;
      params = this._createParams(this.get('selection.dateOne'), linkUUIDs);
      url = this._buildUrl(params);
      return this._fetchAjax(url);
    },
    // Builds the URLs, makes a bulk request
    _fetchComparison: function _fetchComparison(linkUUIDs) {
      var firstRequestParams, secondRequestParams, url;
      firstRequestParams = this._createParams(this.get('selection.dateOne'), linkUUIDs);
      secondRequestParams = this._createParams(this.get('selection.dateTwo'), linkUUIDs);
      url = this._buildBulkUrl([firstRequestParams, secondRequestParams]);
      return this._fetchAjax(url);
    },
    // Creates the parameter string
    // The segments eventName == the uuid string from the .trackables
    _createParams: function _createParams(date, linkUUIDs) {
      var i, len, params, segment, uuid;
      // prepare the segment
      segment = [];
      for (i = 0, len = linkUUIDs.length; i < len; i++) {
        uuid = linkUUIDs[i];
        segment.push('eventName==' + uuid);
      }
      // create the params
      params = getOwner(this).lookup('hoshi-analytics-piwik:piwik-url-params');
      params.setProperties({
        method: 'Events.getName',
        segment: segment.join(','),
        period: this.get('selection.dimension.title').toLowerCase()
      });
      // set the date
      params.setDate(date);
      return params;
    },
    // Makes the actual ajax request
    _fetchAjax: function _fetchAjax(url) {
      var _that;
      _that = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return $.ajax({
          url: url,
          type: "GET"
        }).then(function (data) {
          var pwItems;
          pwItems = [];
          if (_that.get('selection.mode.isSummary')) {
            pwItems = _that._deserializeSummary(data);
          } else if (_that.get('selection.mode.isComparison')) {
            pwItems = _that._deserializeComparison(data);
          }
          return resolve(pwItems);
        }, function (error) {
          return reject(error);
        });
      });
    },
    // Deserializes the response of the summary request
    _deserializeSummary: function _deserializeSummary(data) {
      var i, len, pwItems, rawData;
      pwItems = [];
      for (i = 0, len = data.length; i < len; i++) {
        rawData = data[i];
        pwItems.push(_piwikDataClicks.default.create(rawData));
      }
      return pwItems;
    },
    // Transforms the result array into a object with uuid -> [PiwikDataClicks1, PiwikDataClicks2]
    // if both data sets are available you get an array with arrays
    // [[data1,data2],[data1,data2]]
    // first array = first period
    // second array = second period
    // TODO: Bugfix
    // - if any of the arrays is empty (or both) there is no possibility to
    //   distinguish which one was empty, as the result will always be
    //   uuid -> [PiwikDataClicks1] or uuid -> [PiwikDataClicks2] and the frontend
    //   does not know which one is which one, so the result currently is that
    //   no matter which order the dates were requested, if the first date
    //   has no data the frontend displays 1/0 instead of -0/1
    _deserializeComparison: function _deserializeComparison(data) {
      var comparisonData, i, j, len, len1, pwItems, rawData;
      pwItems = {};
      for (i = 0, len = data.length; i < len; i++) {
        comparisonData = data[i];
        for (j = 0, len1 = comparisonData.length; j < len1; j++) {
          rawData = comparisonData[j];
          if (!(rawData.label in pwItems)) {
            pwItems[rawData.label] = [];
          }
          pwItems[rawData.label].push(_piwikDataClicks.default.create(rawData));
        }
      }
      return pwItems;
    }
  });

  exports.default = PiwikRequestClicks;
});