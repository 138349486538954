define('hoshi-cookie-notification/instance-initializers/browser/setup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SetupInitializer;

  SetupInitializer = {
    name: 'Setup',
    initialize: function initialize(app) {
      var appRoute, cookieService, ext;
      // register a new footer extensibility area item
      ext = app.lookup('service:extensibility');
      ext.addAreaItem('footer', 'cookie-notification');
      // lookup ember-cookies
      cookieService = app.lookup('service:cookies');
      // if not accepted, show render the template
      if (!cookieService.read('hoshi-cookie-notification')) {
        appRoute = app.lookup('route:application');
        return appRoute.reopen({
          renderTemplate: function renderTemplate() {
            this._super();
            return this.render('ext/hoshi-cookie-notification', {
              outlet: 'cookie-notification',
              into: 'application'
            });
          }
        });
      }
    }
  };

  exports.default = SetupInitializer;
});