define('hoshi-analytics-piwik/lib/utils/piwik-dimension', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // Piwik: Simple class that represents the dimension of a selection view.
  // Can be day, week, month or year, has a computed that determins if a dim.
  // is the current one.
  var PiwikDimension;

  PiwikDimension = Ember.Object.extend({
    i18n: Ember.inject.service(),
    currentDimension: Ember.computed.alias('parent.currentDimension'),
    title: '',
    titleI18n: Ember.computed('title', function () {
      return this.get('i18n').t('analytics.dimensions.' + this.get('title').toLowerCase());
    }),
    isDay: Ember.computed.equal('title', 'Day'),
    isWeek: Ember.computed.equal('title', 'Week'),
    isMonth: Ember.computed.equal('title', 'Month'),
    isYear: Ember.computed.equal('title', 'Year'),
    isCurrentDimension: Ember.computed('currentDimension.title', 'title', function () {
      return this.get('currentDimension.title') === this.get('title');
    })
  });

  exports.default = PiwikDimension;
});