define('hoshi-analytics-piwik/lib/data/piwik-data-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // Simple data access object for the data returned by PIWIK API
  // The constructor can be used to set all values to "-" or to set the values
  // from the JSON object returned by the PIWIK API
  var PiwikDataPage;

  PiwikDataPage = Ember.Object.extend({
    piwikProperties: ['label', 'nb_visits', 'nb_uniq_visitors', 'nb_hits', 'sum_time_spent', 'avg_time_on_page', 'bounce_rate', 'exit_rate', 'nb_hits_with_time_generation', 'min_time_generation', 'max_time_generation', 'avg_time_generation', 'url'],
    label: '',
    nb_visits: '',
    nb_uniq_visitors: '',
    nb_hits: '',
    sum_time_spent: '',
    avg_time_on_page: '',
    bounce_rate: '',
    exit_rate: '',
    nb_hits_with_time_generation: '',
    min_time_generation: '',
    max_time_generation: '',
    avg_time_generation: '',
    url: ''
  });

  exports.default = PiwikDataPage;
});