define("hoshi-extensibility/lib/extensibility-areas", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ExtensibilityAreas;

  ExtensibilityAreas = Ember.Object.extend();

  exports.default = ExtensibilityAreas;
});