define('hoshi-analytics-piwik/lib/request/piwik-request-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  ;
  var PiwikRequestBase;

  PiwikRequestBase = Ember.Object.extend({
    piwikUrl: '',
    piwikTokenAuth: '',
    _init: Ember.on('init', function () {
      var config;
      config = getOwner(this).resolveRegistration('config:environment');
      this.set('piwikUrl', "/index.php");
      return this.set('piwikTokenAuth', config.APP.piwikTokenAuth);
    }),
    _buildUrl: function _buildUrl(params) {
      var paramString;
      paramString = params.generateParamString();
      return this.get('piwikUrl') + '?' + paramString;
    },
    _buildBulkUrl: function _buildBulkUrl(paramsArray) {
      var bulkParamStrings, i, paramStrings;
      paramStrings = [];
      i = 0;
      paramsArray.forEach(function (params) {
        return paramStrings.push('urls[' + i++ + ']=' + encodeURIComponent(params.generateParamString()));
      });
      bulkParamStrings = [];
      bulkParamStrings.push("module=API");
      bulkParamStrings.push("method=API.getBulkRequest");
      bulkParamStrings.push("format=json");
      bulkParamStrings.push('token_auth=' + this.get('piwikTokenAuth'));
      return this.get('piwikUrl') + '?' + bulkParamStrings.join("&") + '&' + paramStrings.join("&");
    }
  });

  exports.default = PiwikRequestBase;
});