define('hoshi-click-to-paginate/initializers/hoshi-click-to-paginate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var initialize;

  initialize = function initialize(app) {
    var applicationView, config;
    applicationView = app.resolveRegistration('component:application-view');
    config = app.resolveRegistration('config:environment');
    return applicationView.reopen({
      _registerClickHandler: Ember.on('didInsertElement', function () {
        if (config.options.paginateWithClicks) {
          return this._handleClicksToPaginate();
        }
      }),
      _handleClicksToPaginate: function _handleClicksToPaginate() {
        var _this = this;

        return $('#main').on('mousedown', function (e) {
          var referenceContainer, referenceContainerLeft, referenceContainerRight;
          //return unless left mousebutton was clicked
          if (e.button !== 0) {
            return;
          }
          // page or header layout container
          referenceContainer = $('.page');
          if (!referenceContainer.length) {
            referenceContainer = $('#header--layout-container--content');
          }
          if (!referenceContainer.length) {
            return;
          }
          // are we left or right of the container?
          referenceContainerLeft = referenceContainer.offset().left;
          referenceContainerRight = referenceContainerLeft + referenceContainer.innerWidth();
          // if so => paginate
          if (e.clientX < referenceContainerLeft) {
            return _this.get('controller').send('to_prev_page');
          } else if (e.clientX > referenceContainerRight) {
            return _this.get('controller').send('to_next_page');
          }
        });
      }
    });
  };

  exports.default = {
    name: 'hoshi-click-to-paginate',
    initialize: initialize
  };
  ;
});