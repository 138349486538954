define('hoshi-analytics-piwik/lib/request/piwik-request-page', ['exports', 'hoshi-analytics-piwik/lib/data/piwik-data-page', 'hoshi-analytics-piwik/lib/request/piwik-request-base'], function (exports, _piwikDataPage, _piwikRequestBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember = Ember,
      getOwner = _Ember.getOwner;
  ;
  var PiwikRequestPage;

  PiwikRequestPage = _piwikRequestBase.default.extend({
    // passed to the PiwikUrlParams for the segment query
    book_id: '',
    page_id: '',
    // used to create the different kinds queries
    selection: void 0,
    //#############################################################################
    // Public API
    //#############################################################################
    fetch: function fetch() {
      if (!Ember.isEmpty(this.get('selection.dimension'))) {
        if (this.get('selection.mode.isSummary')) {
          return this._fetchSummary();
        } else if (this.get('selection.mode.isComparison')) {
          return this._fetchComparison();
        } else if (this.get('selection.mode.isChart')) {
          return this._fetchChart();
        } else if (this.get('selection.mode.isRanking')) {
          return this._fetchSummary(true);
        }
      }
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return reject();
      });
    },
    //#############################################################################
    // Private API
    //#############################################################################

    // Builds the URL, makes a simple request
    _fetchSummary: function _fetchSummary() {
      var book_only = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var params, url;
      params = this._createParams(this.get('selection.dateOne'), book_only);
      url = this._buildUrl(params);
      return this._fetchAjax(url);
    },
    // Builds the URLs, makes a bulk request
    _fetchComparison: function _fetchComparison() {
      var firstRequestParams, secondRequestParams, url;
      firstRequestParams = this._createParams(this.get('selection.dateOne'));
      secondRequestParams = this._createParams(this.get('selection.dateTwo'));
      url = this._buildBulkUrl([firstRequestParams, secondRequestParams]);
      return this._fetchAjax(url);
    },
    // Builds the URL, makes a simple request
    // The difference to @see _fetchSummary is the date range
    _fetchChart: function _fetchChart() {
      var params, url;
      params = this._createParams(this.get('selection.dateOne'));
      params.setDateRange(this.get('selection.dateTwo'), this.get('selection.dateOne'));
      url = this._buildUrl(params);
      return this._fetchAjax(url);
    },
    _createParams: function _createParams(date) {
      var book_only = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var params, segment;
      params = getOwner(this).lookup('hoshi-analytics-piwik:piwik-url-params');
      // the segment is a query that ensures that data is only fetched for the
      // current book and current page
      segment = 'customVariablePageValue1==' + this.get('book_id');
      if (!book_only) {
        if (!Ember.isEmpty(this.get('page_id'))) {
          segment += ';customVariablePageValue2==' + this.get('page_id');
        }
      }
      if (book_only) {
        params.set('flat', 1);
      }
      params.set('segment', segment);
      params.set('period', this.get('selection.dimension.title').toLowerCase());
      params.setDate(date);
      return params;
    },
    _fetchAjax: function _fetchAjax(url) {
      var _that;
      _that = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return $.ajax({
          url: url,
          type: "GET"
        }).then(function (data) {
          var pwItems;
          pwItems = [];
          if (_that.get('selection.mode.isSummary')) {
            pwItems = _that._deserializeSummary(data);
          } else if (_that.get('selection.mode.isComparison')) {
            pwItems = _that._deserializeComparison(data);
          } else if (_that.get('selection.mode.isChart')) {
            pwItems = _that._deserializeChart(data);
          } else if (_that.get('selection.mode.isRanking')) {
            pwItems = _that._deserializeRanking(data);
          }
          return resolve(pwItems);
        }, function (error) {
          return reject(error);
        });
      });
    },
    _deserializeSummary: function _deserializeSummary(data) {
      return [new _piwikDataPage.default(data[0])];
    },
    _deserializeComparison: function _deserializeComparison(data) {
      var i, item, len, pwItems, realItem;
      pwItems = [];
      for (i = 0, len = data.length; i < len; i++) {
        item = data[i];
        realItem = item[0];
        pwItems.push(new _piwikDataPage.default(realItem));
      }
      return pwItems;
    },
    _deserializeChart: function _deserializeChart(data) {
      var date, item, pwItems;
      pwItems = {};
      for (date in data) {
        item = data[date];
        if (item.length > 0) {
          pwItems[date] = new _piwikDataPage.default(item[0]);
        } else {
          pwItems[date] = new _piwikDataPage.default();
        }
      }
      return pwItems;
    },
    _deserializeRanking: function _deserializeRanking(data) {
      var i, item, len, pwItems;
      pwItems = [];
      for (i = 0, len = data.length; i < len; i++) {
        item = data[i];
        pwItems.push(new _piwikDataPage.default(item));
      }
      return pwItems;
    }
  });

  exports.default = PiwikRequestPage;
});