define('hoshi-analytics-piwik/lib/mixins/link-analytics-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  ;
  var LinkAnalyticsMixin;

  LinkAnalyticsMixin = Ember.Mixin.create({
    user: Ember.inject.service(),
    piwikAnalyticsSelection: Ember.inject.service(),
    piwikTracker: Ember.inject.service(),
    classNames: ['hoshi-analytics-piwik--trackable-links'],
    // fetches clicks, if a.trackable then piwik is informed
    _initClickTracking: Ember.on('didInsertElement', function () {
      var _this = this;

      return this.$().on('click', 'a.trackable', function (e) {
        return _this.get('piwikTracker').trackLink($(e.target).attr('id'), $(e.target).attr('href'), $(e.target).text());
      });
    }),
    // looks for the analytics controls, of something changes piwik is requested
    // for the current analytics that are then inserted into the DOM as value in
    // the :after element of the links.
    updateLinkClickCount: Ember.on('init', Ember.observer('user.showAnalytics', 'piwikAnalyticsSelection.selection', function () {
      var _this2 = this;

      var linkUUIDs, request;
      if (this.get('piwikAnalyticsSelection.selection.mode.isChart')) {
        // currently chart is not supported
        return;
      }
      if (this.get('user.showAnalytics')) {
        this._initLoadingValues();
        request = this._createPiwikEventRequest();
        linkUUIDs = this._collectUUIDsFromLinks();
        return request.fetch(linkUUIDs).then(function (linkClicks) {
          if (_this2.get('piwikAnalyticsSelection.selection.mode.isSummary')) {
            return _this2._updateLinkClickCountSummary(linkClicks);
          } else if (_this2.get('piwikAnalyticsSelection.selection.mode.isComparison')) {
            return _this2._updateLinkClickCountComparison(linkClicks);
          }
        }, function (err) {
          return console.log("Error", err);
        });
      }
    })),
    // during loading the value should be 0
    _initLoadingValues: function _initLoadingValues() {
      if (this.get('piwikAnalyticsSelection.selection.mode.isComparison')) {
        return $('.trackable').attr('data-content', "0/0");
      }
      return $('.trackable').attr('data-content', "0");
    },
    // the request is created with the selection
    _createPiwikEventRequest: function _createPiwikEventRequest() {
      var request;
      request = getOwner(this).lookup('hoshi-analytics-piwik:piwik-request-clicks');
      request.set('selection', this.get('piwikAnalyticsSelection.selection'));
      return request;
    },
    // jQuery is used to collect all ids (uuidv4) from links with class .trackable
    _collectUUIDsFromLinks: function _collectUUIDsFromLinks() {
      var linkUUIDs;
      linkUUIDs = [];
      $('.trackable').each(function (i, el) {
        return linkUUIDs.push($(el).attr('id'));
      });
      return linkUUIDs;
    },
    // Uses jQuery to update the data-content attribute of .trackable
    // Simply fetches a numeric value from the piwik data and sets it into
    // data-content, which itself is used by CSS (after, content: "attr(content-data)")
    _updateLinkClickCountSummary: function _updateLinkClickCountSummary(linkClicks) {
      var j, len, linkClick, results, selector;
      results = [];
      for (j = 0, len = linkClicks.length; j < len; j++) {
        linkClick = linkClicks[j];
        selector = '#' + linkClick.get('label');
        results.push($(selector).attr('data-content', linkClick.get('nb_visits')));
      }
      return results;
    },
    // Uses jQuery to update the data-content attribute of .trackable
    // This version is called for comparison mode. The object passed needs to be
    // an object with a key (uuidv4) that holds 0,1 or 2 PiwikDataEvent objects,
    // where 0 means no data found, 1 means only for one date some data could be
    // found and two means the comparison is possible
    _updateLinkClickCountComparison: function _updateLinkClickCountComparison(linkClickHash) {
      var key, linkClickComparison, results, str;
      // iterate over the comparison data: key => array[obj1,obj2]
      // TODO @see piwik-request-event _deserializeComparison
      // issue with 1/0 and 0/1 (later is not possible and displayed as 1/0 instead)
      results = [];
      for (key in linkClickHash) {
        linkClickComparison = linkClickHash[key];
        str = '-/-';
        if (linkClickComparison.length === 1) {
          str = linkClickComparison[0].get('nb_visits') + '/0';
        } else if (linkClickComparison.length === 2) {
          str = linkClickComparison[0].get('nb_visits') + '/' + linkClickComparison[1].get('nb_visits');
        }
        results.push($('#' + key).attr('data-content', str));
      }
      return results;
    }
  });

  exports.default = LinkAnalyticsMixin;
});