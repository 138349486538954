define('hoshi-analytics-piwik/lib/utils/piwik-mode', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // Piwik: Simple class that represents a mode. Can be e.g. summary, comparison
  // or chart. Might grow over the time. Has a computed to determin if this is
  // the current mode.
  var PiwikMode;

  PiwikMode = Ember.Object.extend({
    i18n: Ember.inject.service(),
    currentMode: Ember.computed.alias('parent.currentMode'),
    title: '',
    titleI18n: Ember.computed('title', function () {
      return this.get('i18n').t('analytics.modes.' + this.get('title').toLowerCase());
    }),
    isSummary: Ember.computed.equal('title', 'Summary'),
    isComparison: Ember.computed.equal('title', 'Comparison'),
    isChart: Ember.computed.equal('title', 'Chart'),
    isRanking: Ember.computed.equal('title', 'Ranking'),
    needsTwoDates: Ember.computed.or('isComparison', 'isChart'),
    isCurrentMode: Ember.computed('currentMode.title', 'title', function () {
      return this.get('currentMode.title') === this.get('title');
    })
  });

  exports.default = PiwikMode;
});