define("hoshi-module-voting/instance-initializers/setup", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var initialize = exports.initialize = function initialize() {
    return console.log("iniit module voting");
  };

  exports.default = { initialize: initialize };
});