define('hoshi-analytics-piwik/lib/data/piwik-data-clicks', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PiwikDataClicks;

  PiwikDataClicks = Ember.Object.extend({
    piwikProperties: ['label', 'nb_uniq_visitors', 'nb_visits', 'nb_events', 'nb_events_with_value', 'sum_event_value', 'min_event_value', 'max_event_value', 'avg_event_value', 'segment', 'idsubdatatable'],
    label: "",
    nb_uniq_visitors: 0,
    nb_visits: 0,
    nb_events: 0,
    nb_events_with_value: 0,
    sum_event_value: 0,
    min_event_value: 0,
    max_event_value: 0,
    avg_event_value: 0,
    segment: "",
    idsubdatatable: 0
  });

  exports.default = PiwikDataClicks;
});