define('hoshi-i18n/instance-initializers/hoshi-i18n', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var initialize;

  initialize = function initialize(app) {
    var config, ext, ref;
    config = app.resolveRegistration('config:environment');
    if (((ref = config.options) != null ? ref.i18n : void 0) != null && config.options.i18n === true) {
      app.lookup('service:i18n').set('locale', config.options.defaultLocale);
      ext = app.lookup('service:extensibility');
      return ext.addAreaItem('nav-main--toolbar-public', 'language-picker');
    }
  };

  exports.default = {
    name: 'hoshi-i18n',
    after: 'extensibility-initializer',
    initialize: initialize
  };
  ;
});