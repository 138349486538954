define('hoshi-blueimp/instance-initializers/hoshi-blueimp-instance', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var initialize;

  initialize = function initialize(app) {
    var ext;
    ext = app.lookup('service:extensibility');
    return ext.addAreaItem('footer', 'blueimp-player');
  };

  exports.default = {
    name: 'hoshi-blueimp-instance',
    after: 'extensibility-initializer',
    initialize: initialize
  };
  ;
});