define('ember-data-sails/mixins/with-logger', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var LEVELS = exports.LEVELS = 'debug info notice warn error'.split(' ');

  var levelMap = {
    notice: 'log'
  };
  var methods = {};

  LEVELS.forEach(function (level) {
    methods[level] = Ember.run.bind(Ember.Logger, levelMap[level] || level, '[ed-sails]');
  });

  /**
   * Mix logging methods in our class depending on the configured log level
   * @since 0.0.10
   * @class WithLoggerMixin
   * @extends Ember.Logger
   * @extensionFor Ember.Object
   */
  exports.default = Ember.Mixin.create(methods);
});