define('theme-default/instance-initializers/theme-setup', ['exports', 'theme-default/locales/de/translations', 'theme-default/locales/en/translations', 'theme-default/templates/components/page-types/page-type-toc', 'theme-default/templates/components/toolbar/nav-table-of-contents', 'theme-default/templates/components/comments/comments-main', 'theme-default/templates/books-index', 'theme-default/templates/header-content-default', 'ember-validations'], function (exports, _translations, _translations2, _pageTypeToc, _navTableOfContents, _commentsMain, _booksIndex, _headerContentDefault, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = undefined;

  var ThemeSetupInstanceInitializer, initialize;

  exports.initialize = initialize = function initialize(app) {
    var bookModel, i18n, pageBuilder;
    bookModel = app.resolveRegistration('model:book');
    bookModel.reopen({
      sorted_regular_pages: Ember.computed('regular_pages', 'regular_pages.@each.idx', 'regular_pages.@each.subline', function () {
        var currentPage, i, j, lastPage, ref, sortedPages;
        sortedPages = this.get('regular_pages').sortBy('idx');
        for (i = j = 0, ref = sortedPages.length - 1; 0 <= ref ? j <= ref : j >= ref; i = 0 <= ref ? ++j : --j) {
          if (i > 0) {
            lastPage = sortedPages[i - 1];
            currentPage = sortedPages[i];
            if (lastPage.get('subline').toLowerCase() === currentPage.get('subline').toLowerCase()) {
              currentPage.set('hideSubline', true);
              lastPage.set('isLastChapter', false);
            } else {
              lastPage.set('isLastChapter', true);
              currentPage.set('hideSubline', false);
            }
          }
        }
        return sortedPages;
      })
    });

    /*
     * I18N
     */
    i18n = app.lookup('service:i18n');
    i18n.addTranslations('de', _translations.default);
    i18n.addTranslations('en', _translations2.default);

    /*
     * Custom Page Type Toc
     */
    app.register('template:components/page-types/page-type-toc', _pageTypeToc.default, {
      instantiate: true

      /*
       * Books Index Template Overwrite
       */
    });
    app.register('template:books/index', _booksIndex.default, {
      instantiate: true

      /*
       * Custom Header Layout
       */
    });
    app.register('template:components/header-layouts/header-content-default', _headerContentDefault.default, {
      instantiate: true

      /*
       * Books Index Route Overwrite
       */

      /*
       * Login / Logout Handling
       */

      /*
       * Custom Content Area
       */

      /*
       * Page Type Toc inject i18n
       */

      /*
       * Page Builder custom HTMLClasses
       */
    });
    pageBuilder = app.lookup('service:page-builder');
    pageBuilder.addRowStyle('theme.settings.styles.row.paddingTop', 'pt-3');
    pageBuilder.addRowStyle('theme.settings.styles.row.paddingBottom', 'pb-3');
    pageBuilder.addRowStyle('theme.settings.styles.row.paddingTopSmall', 'pt-1');
    pageBuilder.addRowStyle('theme.settings.styles.row.paddingBottomSmall', 'pb-1');
    pageBuilder.addRowStyle('theme.settings.styles.row.vh50', 'vh50');
    pageBuilder.addRowStyle('theme.settings.styles.row.vh100', 'vh100');
    pageBuilder.addRowStyle('theme.settings.styles.row.vh30', 'vh30');
    pageBuilder.addRowStyle('theme.settings.styles.row.alignItemsStart', 'align-items-start');
    pageBuilder.addColStyle('theme.settings.styles.col.paddingTop', 'pt-3');
    pageBuilder.addColStyle('theme.settings.styles.col.paddingBottom', 'pb-3');
    pageBuilder.addColStyle('theme.settings.styles.col.paddingTopSmall', 'pt-1');
    pageBuilder.addColStyle('theme.settings.styles.col.paddingBottomSmall', 'pb-1');
    pageBuilder.addColStyle('theme.settings.styles.col.padding', 'p-3');
    pageBuilder.addColStyle('theme.settings.styles.col.paddingSmall', 'p-1');
    pageBuilder.addColStyle('theme.settings.styles.col.gallery', 'gallery');
    pageBuilder.addColStyle('theme.settings.styles.col.foregroundLight', 'foreground--light');
    return pageBuilder.addColStyle('theme.settings.styles.col.html5Video', 'html5-video');

    /*
     * Redirect Start
     */
  };

  ThemeSetupInstanceInitializer = {
    name: 'theme-setup-instance',
    initialize: initialize
  };

  exports.initialize = initialize;
  exports.default = ThemeSetupInstanceInitializer;
});