define('hoshi-extensibility/lib/extensibility-area', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ExtensibilityArea;

  ExtensibilityArea = Ember.Object.extend({
    name: void 0,
    items: void 0,
    _init: Ember.on('init', function () {
      return this.set('items', Ember.A([]));
    }),
    addItem: function addItem(item) {
      return this.get('items').pushObject(item);
    }
  });

  exports.default = ExtensibilityArea;
});