define('hoshi-search/components/search/search-results', ['exports', 'hoshi-search/templates/components/search/search-results'], function (exports, _searchResults) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var SearchSearchResultsComponent;

  SearchSearchResultsComponent = Ember.Component.extend({
    layout: _searchResults.default,
    store: Ember.inject.service(),
    classNames: ['search--result'],
    parentComponent: void 0,
    searchResult: void 0,
    isPage: Ember.computed.equal('searchResult._type', 'page'),
    isContentarea: Ember.computed.equal('searchResult._type', 'contentarea'),
    page: Ember.computed('searchResult', function () {
      return this.get('store').findRecord('page', this._getPageId());
    }),
    //#############################################################################
    actions: {
      //#############################################################################
      toPage: function toPage() {
        return this.get('parentComponent').send('to_page', this._getPageId());
      }
    },
    //#############################################################################
    // Private API
    //#############################################################################
    _getPageId: function _getPageId() {
      var page_id;
      if (this.get('isPage')) {
        return page_id = this.get('searchResult._id');
      } else {
        return page_id = this.get('searchResult._source.page_id');
      }
    }
  });

  exports.default = SearchSearchResultsComponent;
});