define('hoshi-i18n/services/hoshi-i18n', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var HoshiI18nService;

  HoshiI18nService = Ember.Service.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    // enabled
    enabled: false,
    // the current language, defaults to en
    currentLanguage: Ember.computed.alias('i18n.locale'),
    // all available languages
    allLanguages: [],
    // all active languages
    portalLanguages: Ember.computed.filterBy('allLanguages', 'active', true),
    // active languages without the currently selected
    portalLanguagesWithoutCurrent: Ember.computed('currentLanguage', 'portalLanguages', function () {
      var _this = this;

      return this.get('portalLanguages').filter(function (lang) {
        return lang.get('code') !== _this.get('currentLanguage');
      });
    }),
    // the current language object
    // this object should not be changed, change the currentLanguage instead
    // and currentLanguageObject will recalculate
    currentLanguageObject: Ember.computed('i18n.locale', 'portalLanguages', function () {
      return this.get('portalLanguages').findBy('code', this.get('i18n.locale'));
    }),
    _init: Ember.on('init', function () {
      return this._fetchAllLanguages();
    }),
    _fetchAllLanguages: function _fetchAllLanguages() {
      var _this2 = this;

      return this.get('store').findAll('language').then(function (languages) {
        return _this2.set('allLanguages', languages);
      });
    }
  });

  exports.default = HoshiI18nService;
});