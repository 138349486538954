define('hoshi-analytics-piwik/lib/mixins/analytics-and-tracking-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PageTrackerMixin;

  PageTrackerMixin = Ember.Mixin.create({
    piwikTracker: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this._super();
      this.render('piwik-analytics', {
        outlet: 'analytics',
        into: 'application'
      });
      return this.render('piwik-toolbar-dropdown', {
        outlet: 'analytics',
        into: 'toolbars/page'
      });
    },
    actions: {
      didTransition: function didTransition() {
        // ensure this is a page model
        if (this.currentModel.constructor.modelName && this.currentModel.constructor.modelName === 'page') {
          return this.get('piwikTracker').trackPage(this.currentModel, this.modelFor('book'));
        }
      }
    }
  });

  exports.default = PageTrackerMixin;
});