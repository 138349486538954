define('hoshi-i18n/models/language', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var Language;

  Language = _emberData.default.Model.extend({
    code: _emberData.default.attr('string'),
    locale: _emberData.default.attr('string'),
    flag_code: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    name_local: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean')
  });

  exports.default = Language;
});