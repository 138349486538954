define("hoshi-analytics-piwik/lib/utils/piwik-chart-transform", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  // Class that has only one static method transform. Takes the raw item data
  // from piwik-analytics-request/fetch and and transforms it into ember-cli-chart
  // conform data for the chart.

  // TODO:
  // - #1 make everything dynamic
  var PiwikChartTransform;

  PiwikChartTransform = function () {
    function PiwikChartTransform() {
      _classCallCheck(this, PiwikChartTransform);
    }

    _createClass(PiwikChartTransform, null, [{
      key: "transform",
      value: function transform(rawData) {
        var data, dataset, dataset2, date, item, labels;
        labels = [];
        dataset = {
          label: "Hits",
          fillColor: "rgba(220,220,220,0.2)",
          strokeColor: "rgba(220,220,220,1)",
          pointColor: "rgba(220,220,220,1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(220,220,220,1)",
          data: []
        };
        dataset2 = {
          label: "Visits",
          fillColor: "rgba(151,187,205,0.2)",
          strokeColor: "rgba(151,187,205,1)",
          pointColor: "rgba(151,187,205,1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(151,187,205,1)",
          data: []
        };
        for (date in rawData) {
          item = rawData[date];
          labels.push(date);
          dataset.data.push(item.get('nb_hits'));
          dataset2.data.push(item.get('nb_visits'));
        }
        data = {};
        data.labels = labels;
        data.datasets = [];
        data.datasets.push(dataset);
        data.datasets.push(dataset2);
        return data;
      }
    }]);

    return PiwikChartTransform;
  }();

  exports.default = PiwikChartTransform;
});