define('hoshi-blueimp/initializers/hoshi-blueimp', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // Takes two parameters: container and application
  var HoshiBlueimpInitializer, initialize;

  exports.initialize = initialize = function initialize(app) {
    var bookPageRoute, pageTypeRegularComponent;
    bookPageRoute = app.resolveRegistration('route:book.page');
    bookPageRoute.reopen({
      renderTemplate: function renderTemplate() {
        this._super();
        return this.render('blueimp/blueimp', {
          outlet: 'blueimp-player',
          into: 'application'
        });
      }
    });
    pageTypeRegularComponent = app.resolveRegistration('component:page-types.page-type-regular');
    return pageTypeRegularComponent.reopen({
      click: function click(e) {
        var parent;
        if (e.target.tagName === "IMG") {
          e.preventDefault();
          parent = $(e.target).parents('.gallery');
          if (parent.length) {
            return this._showBlueimpOnImgClicks($(e.target), parent.find("img"));
          } else {
            return this._showBlueimpOnImgClicks($(e.target), this.$().find("img"));
          }
        }
      },
      _showBlueimpOnImgClicks: function _showBlueimpOnImgClicks(target, $images) {
        var gallery, galleryImages, idx;
        galleryImages = [];
        // find out the index of the clicked image
        idx = $images.index(target);
        // collect the images
        $images.each(function (i, el) {
          if ($(el).attr('src')) {
            return galleryImages.push($(el).attr('src'));
          } else if ($(el).attr('href')) {
            return galleryImages.push($(el).attr('href'));
          }
        });
        // initialize the gallry
        gallery = blueimp.Gallery(galleryImages);
        // immediately slide to the clicked image
        return gallery.slide(idx, 0);
      }
    });
  };

  HoshiBlueimpInitializer = {
    name: 'hoshi-blueimp',
    initialize: initialize
  };

  exports.initialize = initialize;
  exports.default = HoshiBlueimpInitializer;
});