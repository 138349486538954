define('hoshi-search/components/search/search-dialog', ['exports', 'hoshi-search/templates/components/search/search-dialog'], function (exports, _searchDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  ;
  var SearchSearchDialogComponent;

  SearchSearchDialogComponent = Ember.Component.extend({
    layout: _searchDialog.default,
    store: Ember.inject.service(),
    host: void 0,
    classNames: ['search--search-field'],
    q: '',
    searchResults: Ember.A([]),
    suggests: Ember.A([]),
    suggest: '',
    shouldDisplayResults: Ember.computed.gt('q.length', 1),
    _init: Ember.on('init', function () {
      var config;
      config = getOwner(this).resolveRegistration('config:environment');
      return this.set('host', config.host || "/"); // "#{config.protocol}:#{config.host}/"
    }),

    // observes the input field for q, starts search after 300ms delay after
    // the last change
    qChanged: Ember.observer('q', function () {
      if (this.get('q.length') > 1) {
        return Ember.run.debounce(this, this._startSearch, 300);
      } else {
        return this.set('searchResults', Ember.A([]));
      }
    }),
    // generates a suggestion based on the suggestion answer from the search query
    // iterates through all words suggestions of the different suggestion fields
    // and replaces the query with the most scoring suggested words
    generateDidYouMean: Ember.observer('suggests', function () {
      var _, bestSuggestionForWord, i, numberOfSuggestions, ref, suggest, suggestionWordsArray, suggests, wordIndex, wordSuggestion;
      this.set('suggest', '');
      // the initial suggest is based on the query
      suggest = this.get('q').toLowerCase();
      // all available suggests, set during the search
      suggests = this.get('suggests');
      // number of suggestions available from the ES answer
      numberOfSuggestions = this._getNumberOfSuggestions(suggests);
      // no suggestions -> stop it
      if (numberOfSuggestions === 0) {
        return;
      }
      // iterate over all words
      for (wordIndex = i = 0, ref = numberOfSuggestions - 1; 0 <= ref ? i <= ref : i >= ref; wordIndex = 0 <= ref ? ++i : --i) {
        // remind the best suggestion to set it after the iteration
        bestSuggestionForWord = void 0;
        // iterate over all fields (headline, subline, content, ...?)
        for (_ in suggests) {
          suggestionWordsArray = suggests[_];
          // get the fields suggestion for the current word index (e.g. content_suggest[0])
          wordSuggestion = suggestionWordsArray[wordIndex];
          // if there is an suggestion at all AND ( the best suggestion is not yet set or the score is better)
          if (wordSuggestion.options.length > 0 && (Ember.isEmpty(bestSuggestionForWord) || wordSuggestion.options[0].score > bestSuggestionForWord.options[0].score)) {
            // remind the current word suggestion as the best suggestion
            bestSuggestionForWord = wordSuggestion;
          }
        }
        // replace the word in the suggest string with the best suggestion
        if (bestSuggestionForWord) {
          suggest = suggest.replace(bestSuggestionForWord.text, bestSuggestionForWord.options[0].text);
        }
      }
      // only set suggest if different from q
      if (suggest !== this.get('q').toLowerCase()) {
        return this.set('suggest', suggest);
      } else {
        return this.set('suggest', '');
      }
    }),
    //#############################################################################
    actions: {
      //#############################################################################
      startSearch: function startSearch() {
        return this._startSearch();
      },
      searchForSuggestion: function searchForSuggestion() {
        this.set('q', this.get('suggest'));
        return this.set('suggest', '');
      },
      to_page: function to_page(page_id) {
        var _this = this;

        return this.get('store').findRecord('page', page_id).then(function (page) {
          return Ember.RSVP.hash({
            page: page,
            book: page.get('book')
          });
        }).then(function (result) {
          return _this.sendAction('to_page_in_book', result.book, result.page);
        }, function (error) {
          return console.log("not found");
        });
      }
    },
    //#############################################################################
    // Private API
    //#############################################################################
    _startSearch: function _startSearch() {
      var _this2 = this;

      return this._execSearch().then(function (searchResults) {
        console.log("*************************************************************");
        console.log(searchResults);
        console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv");
        _this2.set('suggests', searchResults.suggest);
        if (searchResults.hits.total > 0) {
          return _this2.set('searchResults', searchResults.hits.hits);
        } else {
          return _this2.set('searchResults', Ember.A([]));
        }
      });
    },
    _execSearch: function _execSearch() {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return Ember.$.ajax({
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          url: _this3.host + 'api/search/' + _this3.get('q'),
          type: 'POST',
          contentType: 'application/json'
        }).then(function (response) {
          return resolve(response);
        }, function (xhr, status, error) {
          var response_text;
          response_text = JSON.parse(xhr.responseText);
          return reject(response_text.error);
        });
      });
    },
    _getNumberOfSuggestions: function _getNumberOfSuggestions(suggests) {
      var firstKey;
      firstKey = Object.keys(suggests)[0];
      return suggests[firstKey].length;
    }
  });

  exports.default = SearchSearchDialogComponent;
});