define('hoshi-search/components/search/search-button', ['exports', 'hoshi-search/templates/components/search/search-button'], function (exports, _searchButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var SearchSearchButtonComponent;

  SearchSearchButtonComponent = Ember.Component.extend({
    layout: _searchButton.default,
    tagName: 'li',
    modal: Ember.inject.service(),
    actions: {
      openSearch: function openSearch() {
        this.get('modal').open('search');
        return Ember.run.scheduleOnce('afterRender', this, function () {
          return $('.search--search-input-group input').trigger('focus');
        });
      }
    }
  });

  exports.default = SearchSearchButtonComponent;
});