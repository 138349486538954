define('hoshi-extensibility/services/extensibility', ['exports', 'hoshi-extensibility/lib/extensibility-area', 'hoshi-extensibility/lib/extensibility-areas'], function (exports, _extensibilityArea, _extensibilityAreas) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ExtensibilityService;

  ExtensibilityService = Ember.Service.extend({
    _init: Ember.on('init', function () {
      // @set 'areas', {}
      return this.set('areas', _extensibilityAreas.default.create());
    }),
    addArea: function addArea(areaName) {
      var areas;
      areas = this.get('areas');
      if (areas.hasOwnProperty(areaName)) {
        console.warn('[ExtensibilityService:addArea]: Area ' + areaName + ' already exists');
      }
      return areas.set(areaName, _extensibilityArea.default.create({
        name: areaName
      }));
    },
    addAreaItem: function addAreaItem(areaName, itemName) {
      var area, areas;
      areas = this.get('areas');
      if (!areas.hasOwnProperty(areaName)) {
        console.warn('[ExtensibilityService:addAreaItem]: Area ' + areaName + ' does not yet exist, a new area with name ' + areaName + ' is created');
        this.addArea(areaName);
      }
      area = areas.get(areaName);
      return area.addItem(itemName);
    }
  });

  exports.default = ExtensibilityService;
});