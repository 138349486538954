enifed('ember-glimmer/renderer', ['exports', 'ember-babel', 'ember-glimmer/utils/references', 'ember-metal', '@glimmer/reference', 'ember-views', 'ember-glimmer/component', 'ember-glimmer/component-managers/root', 'ember-glimmer/component-managers/outlet', 'ember-debug'], function (exports, _emberBabel, _references, _emberMetal, _reference, _emberViews, _component, _root2, _outlet, _emberDebug) {
  'use strict';

  exports.InteractiveRenderer = exports.InertRenderer = undefined;
  exports._resetRenderers = function () {
    renderers.length = 0;
  };
  var backburner = _emberMetal.run.backburner;

  var DynamicScope = function () {
    function DynamicScope(view, outletState, rootOutletState) {

      this.view = view;
      this.outletState = outletState;
      this.rootOutletState = rootOutletState;
    }

    DynamicScope.prototype.child = function () {
      return new DynamicScope(this.view, this.outletState, this.rootOutletState);
    };

    DynamicScope.prototype.get = function (key) {
      false && !(key === 'outletState') && (0, _emberDebug.assert)('Using `-get-dynamic-scope` is only supported for `outletState` (you used `' + key + '`).', key === 'outletState');

      return this.outletState;
    };

    DynamicScope.prototype.set = function (key, value) {
      false && !(key === 'outletState') && (0, _emberDebug.assert)('Using `-with-dynamic-scope` is only supported for `outletState` (you used `' + key + '`).', key === 'outletState');

      this.outletState = value;
      return value;
    };

    return DynamicScope;
  }();

  var RootState = function () {
    function RootState(root, env, template, self, parentElement, dynamicScope) {
      var _this = this;

      false && !template && (0, _emberDebug.assert)('You cannot render `' + self.value() + '` without a template.', template);

      this.id = (0, _emberViews.getViewId)(root);
      this.env = env;
      this.root = root;
      this.result = undefined;
      this.shouldReflush = false;
      this.destroyed = false;
      this._removing = false;

      var options = this.options = {
        alwaysRevalidate: false
      };

      this.render = function () {
        var iterator = template.render(self, parentElement, dynamicScope);
        var iteratorResult = void 0;

        do {
          iteratorResult = iterator.next();
        } while (!iteratorResult.done);

        var result = _this.result = iteratorResult.value;

        // override .render function after initial render
        _this.render = function () {
          return result.rerender(options);
        };
      };
    }

    RootState.prototype.isFor = function (possibleRoot) {
      return this.root === possibleRoot;
    };

    RootState.prototype.destroy = function () {
      var result = this.result,
          env = this.env,
          needsTransaction;

      this.destroyed = true;

      this.env = null;
      this.root = null;
      this.result = null;
      this.render = null;

      if (result) {
        /*
         Handles these scenarios:
          * When roots are removed during standard rendering process, a transaction exists already
           `.begin()` / `.commit()` are not needed.
         * When roots are being destroyed manually (`component.append(); component.destroy() case), no
           transaction exists already.
         * When roots are being destroyed during `Renderer#destroy`, no transaction exists
          */
        needsTransaction = !env.inTransaction;


        if (needsTransaction) {
          env.begin();
        }

        result.destroy();

        if (needsTransaction) {
          env.commit();
        }
      }
    };

    return RootState;
  }();

  var renderers = [];

  (0, _emberMetal.setHasViews)(function () {
    return renderers.length > 0;
  });

  function register(renderer) {
    false && !(renderers.indexOf(renderer) === -1) && (0, _emberDebug.assert)('Cannot register the same renderer twice', renderers.indexOf(renderer) === -1);

    renderers.push(renderer);
  }

  function deregister(renderer) {
    var index = renderers.indexOf(renderer);
    false && !(index !== -1) && (0, _emberDebug.assert)('Cannot deregister unknown unregistered renderer', index !== -1);

    renderers.splice(index, 1);
  }

  function K() {}

  var loops = 0;


  backburner.on('begin', function () {
    var i;

    for (i = 0; i < renderers.length; i++) {
      renderers[i]._scheduleRevalidate();
    }
  });
  backburner.on('end', function () {
    var i;

    for (i = 0; i < renderers.length; i++) {
      if (!renderers[i]._isValid()) {
        if (loops > 10) {
          loops = 0;
          // TODO: do something better
          renderers[i].destroy();
          throw new Error('infinite rendering invalidation detected');
        }
        loops++;
        return backburner.join(null, K);
      }
    }
    loops = 0;
  });

  var Renderer = function () {
    function Renderer(env, rootTemplate) {
      var _viewRegistry = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _emberViews.fallbackViewRegistry;

      var destinedForDOM = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;


      this._env = env;
      this._rootTemplate = rootTemplate;
      this._viewRegistry = _viewRegistry;
      this._destinedForDOM = destinedForDOM;
      this._destroyed = false;
      this._roots = [];
      this._lastRevision = null;
      this._isRenderingRoots = false;
      this._removedRoots = [];
    }

    // renderer HOOKS

    Renderer.prototype.appendOutletView = function (view, target) {
      var definition = new _outlet.TopLevelOutletComponentDefinition(view);
      var outletStateReference = view.toReference();
      var targetObject = view.outletState.render.controller;

      this._appendDefinition(view, definition, target, outletStateReference, targetObject);
    };

    Renderer.prototype.appendTo = function (view, target) {
      var rootDef = new _root2.RootComponentDefinition(view);

      this._appendDefinition(view, rootDef, target);
    };

    Renderer.prototype._appendDefinition = function (root, definition, target) {
      var outletStateReference = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _reference.UNDEFINED_REFERENCE;
      var targetObject = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

      var self = new _references.RootReference(definition);
      var dynamicScope = new DynamicScope(null, outletStateReference, outletStateReference, true, targetObject);
      var rootState = new RootState(root, this._env, this._rootTemplate, self, target, dynamicScope);

      this._renderRoot(rootState);
    };

    Renderer.prototype.rerender = function () {
      this._scheduleRevalidate();
    };

    Renderer.prototype.register = function (view) {
      var id = (0, _emberViews.getViewId)(view);
      false && !!this._viewRegistry[id] && (0, _emberDebug.assert)('Attempted to register a view with an id already in use: ' + id, !this._viewRegistry[id]);

      this._viewRegistry[id] = view;
    };

    Renderer.prototype.unregister = function (view) {
      delete this._viewRegistry[(0, _emberViews.getViewId)(view)];
    };

    Renderer.prototype.remove = function (view) {
      view._transitionTo('destroying');

      this.cleanupRootFor(view);

      (0, _emberViews.setViewElement)(view, null);

      if (this._destinedForDOM) {
        view.trigger('didDestroyElement');
      }

      if (!view.isDestroying) {
        view.destroy();
      }
    };

    Renderer.prototype.cleanupRootFor = function (view) {
      // no need to cleanup roots if we have already been destroyed
      if (this._destroyed) {
        return;
      }

      var roots = this._roots,
          root;

      // traverse in reverse so we can remove items
      // without mucking up the index
      var i = this._roots.length;
      while (i--) {
        root = roots[i];

        if (root.isFor(view)) {
          root.destroy();
          roots.splice(i, 1);
        }
      }
    };

    Renderer.prototype.destroy = function () {
      if (this._destroyed) {
        return;
      }
      this._destroyed = true;
      this._clearAllRoots();
    };

    Renderer.prototype.getElement = function () {
      // overridden in the subclasses
    };

    Renderer.prototype.getBounds = function (view) {
      var bounds = view[_component.BOUNDS];

      var parentElement = bounds.parentElement();
      var firstNode = bounds.firstNode();
      var lastNode = bounds.lastNode();

      return { parentElement: parentElement, firstNode: firstNode, lastNode: lastNode };
    };

    Renderer.prototype.createElement = function (tagName) {
      return this._env.getAppendOperations().createElement(tagName);
    };

    Renderer.prototype._renderRoot = function (root) {
      var roots = this._roots;

      roots.push(root);

      if (roots.length === 1) {
        register(this);
      }

      this._renderRootsTransaction();
    };

    Renderer.prototype._renderRoots = function () {
      var roots = this._roots,
          env = this._env,
          removedRoots = this._removedRoots,
          i,
          root,
          shouldReflush,
          _root,
          rootIndex;

      var globalShouldReflush = void 0,
          initialRootsLength = void 0;

      do {
        env.begin();

        // ensure that for the first iteration of the loop
        // each root is processed
        initialRootsLength = roots.length;
        globalShouldReflush = false;

        for (i = 0; i < roots.length; i++) {
          root = roots[i];


          if (root.destroyed) {
            // add to the list of roots to be removed
            // they will be removed from `this._roots` later
            removedRoots.push(root);

            // skip over roots that have been marked as destroyed
            continue;
          }

          shouldReflush = root.shouldReflush;

          // when processing non-initial reflush loops,
          // do not process more roots than needed

          if (i >= initialRootsLength && !shouldReflush) {
            continue;
          }

          root.options.alwaysRevalidate = shouldReflush;
          // track shouldReflush based on this roots render result
          shouldReflush = root.shouldReflush = (0, _emberMetal.runInTransaction)(root, 'render');

          // globalShouldReflush should be `true` if *any* of
          // the roots need to reflush
          globalShouldReflush = globalShouldReflush || shouldReflush;
        }

        this._lastRevision = _reference.CURRENT_TAG.value();

        env.commit();
      } while (globalShouldReflush || roots.length > initialRootsLength);

      // remove any roots that were destroyed during this transaction
      while (removedRoots.length) {
        _root = removedRoots.pop();
        rootIndex = roots.indexOf(_root);

        roots.splice(rootIndex, 1);
      }

      if (this._roots.length === 0) {
        deregister(this);
      }
    };

    Renderer.prototype._renderRootsTransaction = function () {
      if (this._isRenderingRoots) {
        // currently rendering roots, a new root was added and will
        // be processed by the existing _renderRoots invocation
        return;
      }

      // used to prevent calling _renderRoots again (see above)
      // while we are actively rendering roots
      this._isRenderingRoots = true;

      var completedWithoutError = false;
      try {
        this._renderRoots();
        completedWithoutError = true;
      } finally {
        if (!completedWithoutError) {
          this._lastRevision = _reference.CURRENT_TAG.value();
        }
        this._isRenderingRoots = false;
      }
    };

    Renderer.prototype._clearAllRoots = function () {
      var roots = this._roots,
          i,
          root;
      for (i = 0; i < roots.length; i++) {
        root = roots[i];

        root.destroy();
      }

      this._removedRoots.length = 0;
      this._roots = null;

      // if roots were present before destroying
      // deregister this renderer instance
      if (roots.length) {
        deregister(this);
      }
    };

    Renderer.prototype._scheduleRevalidate = function () {
      backburner.scheduleOnce('render', this, this._revalidate);
    };

    Renderer.prototype._isValid = function () {
      return this._destroyed || this._roots.length === 0 || _reference.CURRENT_TAG.validate(this._lastRevision);
    };

    Renderer.prototype._revalidate = function () {
      if (this._isValid()) {
        return;
      }
      this._renderRootsTransaction();
    };

    return Renderer;
  }();

  exports.InertRenderer = function (_Renderer) {
    (0, _emberBabel.inherits)(InertRenderer, _Renderer);

    function InertRenderer() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _Renderer.apply(this, arguments));
    }

    InertRenderer.create = function (_ref) {
      var env = _ref.env,
          rootTemplate = _ref.rootTemplate,
          _viewRegistry = _ref._viewRegistry;

      return new this(env, rootTemplate, _viewRegistry, false);
    };

    InertRenderer.prototype.getElement = function () {
      throw new Error('Accessing `this.element` is not allowed in non-interactive environments (such as FastBoot).');
    };

    return InertRenderer;
  }(Renderer);

  exports.InteractiveRenderer = function (_Renderer2) {
    (0, _emberBabel.inherits)(InteractiveRenderer, _Renderer2);

    function InteractiveRenderer() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _Renderer2.apply(this, arguments));
    }

    InteractiveRenderer.create = function (_ref2) {
      var env = _ref2.env,
          rootTemplate = _ref2.rootTemplate,
          _viewRegistry = _ref2._viewRegistry;

      return new this(env, rootTemplate, _viewRegistry, true);
    };

    InteractiveRenderer.prototype.getElement = function (view) {
      return (0, _emberViews.getViewElement)(view);
    };

    return InteractiveRenderer;
  }(Renderer);
});