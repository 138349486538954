define('hoshi-search/instance-initializers/browser/hoshi-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var initialize;

  initialize = function initialize(app) {
    var RenderSearchMixin, ext;
    ext = app.lookup('service:extensibility');
    ext.addAreaItem('toolbar--reading-controls', 'search-button');
    ext.addAreaItem('footer', 'search-dialog');
    RenderSearchMixin = Ember.Mixin.create({
      renderTemplate: function renderTemplate() {
        this._super();
        return this.render('search/search', {
          outlet: 'search-dialog',
          into: 'application'
        });
      }
    });
    app.lookup('route:book.page').reopen(RenderSearchMixin, {
      renderTemplate: function renderTemplate() {
        this._super();
        return this.render('search/search-button', {
          outlet: 'search-button',
          into: 'toolbars.page'
        });
      }
    });
    return app.lookup('route:books').reopen(RenderSearchMixin, {
      renderTemplate: function renderTemplate() {
        this._super();
        return this.render('search/search-button', {
          outlet: 'search-button',
          into: 'toolbars.books'
        });
      }
    });
  };

  exports.default = {
    name: 'hoshi-search',
    after: 'extensibility-initializer',
    initialize: initialize
  };
  ;
});